import {css} from "../../../_snowpack/pkg/@emotion/react.js";
const styles = {
  loader: css`
    display: inline-block;
    height: auto;
    line-height: 0;
    text-align: center;
    vertical-align: top;

    svg {
      enable-background: new 0 0 50 50;
      height: auto;
      width: 100%;
    }
  `
};
export default styles;
