import html2canvas from "../../../_snowpack/pkg/html2canvas.js";
export function getBrowser() {
  const nAgt = navigator.userAgent;
  let browserName = navigator.appName;
  let fullVersion = "" + parseFloat(navigator.appVersion);
  let nameOffset, verOffset, ix;
  if ((verOffset = nAgt.indexOf("Opera")) != -1) {
    browserName = "Opera";
    fullVersion = nAgt.substring(verOffset + 6);
    if ((verOffset = nAgt.indexOf("Version")) != -1)
      fullVersion = nAgt.substring(verOffset + 8);
  } else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
    browserName = "Microsoft Internet Explorer";
    fullVersion = nAgt.substring(verOffset + 5);
  } else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
    browserName = "Chrome";
    fullVersion = nAgt.substring(verOffset + 7);
  } else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
    browserName = "Safari";
    fullVersion = nAgt.substring(verOffset + 7);
    if ((verOffset = nAgt.indexOf("Version")) != -1)
      fullVersion = nAgt.substring(verOffset + 8);
  } else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
    browserName = "Firefox";
    fullVersion = nAgt.substring(verOffset + 8);
  } else if ((nameOffset = nAgt.lastIndexOf(" ") + 1) < (verOffset = nAgt.lastIndexOf("/"))) {
    browserName = nAgt.substring(nameOffset, verOffset);
    fullVersion = nAgt.substring(verOffset + 1);
    if (browserName.toLowerCase() == browserName.toUpperCase()) {
      browserName = navigator.appName;
    }
  }
  if ((ix = fullVersion.indexOf(";")) != -1)
    fullVersion = fullVersion.substring(0, ix);
  if ((ix = fullVersion.indexOf(" ")) != -1)
    fullVersion = fullVersion.substring(0, ix);
  return `${browserName} ${fullVersion}`;
}
export function getUserAgent() {
  return navigator.userAgent;
}
export function getOS() {
  let userOS = "Unknown OS";
  if (navigator.appVersion.indexOf("Win") != -1) {
    userOS = "Windows";
  }
  if (navigator.appVersion.indexOf("Mac") != -1) {
    userOS = "MacOS";
  }
  if (navigator.appVersion.indexOf("X11") != -1) {
    userOS = "UNIX";
  }
  if (navigator.appVersion.indexOf("Linux") != -1) {
    userOS = "Linux";
  }
  return userOS;
}
export function getSelector(e) {
  const id = e.target["id"];
  const classNames = e.target["className"].split(" ").join(".");
  if (id && !classNames) {
    return `#${id}`;
  }
  if (id && classNames) {
    return `#${id}.${classNames}`;
  }
  if (!id && classNames) {
    return `.${classNames}`;
  }
  return "";
}
export function getBrowserDimensions() {
  return `${window.innerWidth}x${window.innerHeight}`;
}
export function getDisplayResolution() {
  return `${window.screen.width * window.devicePixelRatio}x${window.screen.height * window.devicePixelRatio}`;
}
export async function captureScreen() {
  return await html2canvas(document.getElementsByTagName("body")[0], {
    foreignObjectRendering: true,
    allowTaint: true,
    height: window.innerHeight,
    proxy: "https://bugcatcher.io/api/screenshot/proxy",
    width: window.innerWidth,
    useCORS: true,
    x: window.pageXOffset,
    y: window.pageYOffset
  }).then((canvas) => {
    const data = canvas.toDataURL("image/png").replace("data:image/png;base64,", "");
    return data;
  });
}
export const makeLocalStorageKey = (suffix) => `bugcatcher-${suffix}`.toLowerCase().replace(/[^a-z\d]/g, "-").replace(/--+/, "-");
export const projectInitials = (projectName) => {
  let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");
  let initials = [...projectName.matchAll(rgx)];
  initials = ((initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")).toUpperCase();
  return initials;
};
export function positionWidget(x, y) {
  const widgetWidth = 300;
  const widgetHeight = 480;
  const windowWidth = window?.innerWidth;
  const windowHeight = window?.innerHeight;
  let newX = x;
  let newY = y;
  if (x + widgetWidth >= windowWidth) {
    newX = x - widgetWidth - 36;
  }
  if (y + widgetHeight >= windowHeight) {
    newY = y - widgetHeight - 15;
    if (0 > newY - widgetHeight) {
      if (y > windowHeight * 0.5) {
        newY = windowHeight - widgetHeight - 20;
      } else {
        newY = 20;
      }
    }
  }
  return {
    x: newX,
    y: newY
  };
}
