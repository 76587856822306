import {css} from "../../../_snowpack/pkg/@emotion/react.js";
import theme from "../../lib/theme.js";
const styles = {
  tooltip: css`
    align-items: center;
    background-color: ${theme.colors.gray800};
    bottom: 22px;
    box-sizing: border-box;
    color: #fff;
    display: flex;
    font-family: ${theme.fontFamily};
    font-size: 14px;
    height: 40px;
    line-height: 1;
    min-width: 140px;
    padding: 0 18px;
    position: fixed;
    right: 95px;
    white-space: nowrap;

    &:after {
      border-bottom: 20px solid transparent;
      border-left: 20px solid ${theme.colors.gray800};
      border-top: 20px solid transparent;
      content: '';
      height: 0px;
      position: absolute;
      right: -20px;
      top: 0;
      width: 0px;
    }
  `
};
export default styles;
