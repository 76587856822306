import {jsx} from "../../../_snowpack/pkg/@emotion/react.js";
import {useContext} from "../../../_snowpack/pkg/react.js";
import Close from "../../assets/Close.js";
import PoweredBy from "../../assets/PoweredBy.js";
import BugContext from "../../lib/context.js";
import styles from "./styles.js";
const Ticket = () => {
  const {state, send} = useContext(BugContext);
  const ticket = state.context.viewTicket;
  return /* @__PURE__ */ jsx("div", {
    css: styles.container
  }, /* @__PURE__ */ jsx("header", {
    css: styles.header
  }, /* @__PURE__ */ jsx("div", null, /* @__PURE__ */ jsx("h2", null, "Ticket Details"), /* @__PURE__ */ jsx("p", null, "Here's all the data we captured for this bug report.")), /* @__PURE__ */ jsx("button", {
    css: styles.close,
    onClick: () => send({type: "EXIT_TICKET"})
  }, /* @__PURE__ */ jsx(Close, null))), /* @__PURE__ */ jsx("div", {
    css: styles.content
  }, /* @__PURE__ */ jsx("a", {
    href: ticket?.screenshot[0]?.url,
    css: styles.screenshotContainer,
    target: "_blank",
    rel: "noopener noreferrer"
  }, /* @__PURE__ */ jsx("img", {
    src: ticket?.screenshot[0]?.url,
    css: styles.screenshot
  })), /* @__PURE__ */ jsx("ul", null, /* @__PURE__ */ jsx("li", null, /* @__PURE__ */ jsx("span", null, "Ticket Title"), /* @__PURE__ */ jsx("span", null, ticket?.title)), /* @__PURE__ */ jsx("li", null, /* @__PURE__ */ jsx("span", null, "Created"), /* @__PURE__ */ jsx("span", null, ticket?.createdAt)), /* @__PURE__ */ jsx("li", null, /* @__PURE__ */ jsx("span", null, "Status"), /* @__PURE__ */ jsx("span", null, ticket?.status, ticket?.status === "OPEN" && /* @__PURE__ */ jsx("button", {
    css: styles.closeButton,
    onClick: () => send({type: "CLOSE_TICKET"})
  }, "Mark as closed"))), /* @__PURE__ */ jsx("li", null, /* @__PURE__ */ jsx("span", null, "Reported By"), /* @__PURE__ */ jsx("span", null, ticket?.user?.name)), /* @__PURE__ */ jsx("li", null, /* @__PURE__ */ jsx("span", null, "Notes"), /* @__PURE__ */ jsx("span", null, ticket?.description)), /* @__PURE__ */ jsx("li", null, /* @__PURE__ */ jsx("span", null, "Severity"), /* @__PURE__ */ jsx("span", null, ticket?.severity)), /* @__PURE__ */ jsx("li", null, /* @__PURE__ */ jsx("span", null, "Selector"), /* @__PURE__ */ jsx("span", null, ticket?.selector)), /* @__PURE__ */ jsx("li", null, /* @__PURE__ */ jsx("span", null, "Browser"), /* @__PURE__ */ jsx("span", null, ticket?.browser)), /* @__PURE__ */ jsx("li", null, /* @__PURE__ */ jsx("span", null, "Browser Dimensions"), /* @__PURE__ */ jsx("span", null, ticket?.browserDimensions)), /* @__PURE__ */ jsx("li", null, /* @__PURE__ */ jsx("span", null, "Display Resolution"), /* @__PURE__ */ jsx("span", null, ticket?.displayResolution)), /* @__PURE__ */ jsx("li", null, /* @__PURE__ */ jsx("span", null, "User Agent"), /* @__PURE__ */ jsx("span", null, ticket?.userAgent)))), /* @__PURE__ */ jsx("footer", {
    css: styles.footer
  }, /* @__PURE__ */ jsx("a", {
    href: "https://bugcatcher.io",
    target: "_blank",
    css: styles.link
  }, /* @__PURE__ */ jsx(PoweredBy, {
    css: styles.poweredBy
  }))));
};
export default Ticket;
