import {css} from "../../../_snowpack/pkg/@emotion/react.js";
import theme from "../../lib/theme.js";
const styles = {
  container: css`
    background-color: #fff;
    box-shadow: ${theme.boxShadow};
    box-sizing: border-box;
    font-family: ${theme.fontFamily};
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    width: 400px;
    z-index: 9999;
  `,
  header: css`
    background-color: ${theme.colors.green700};
    padding: 20px;
    display: flex;

    div {
      flex: 1;
    }

    h2 {
      color: #fff;
      margin: 0 0 4px;
      font-size: 18px;
      padding: 0;
      line-height: 1;
    }

    p {
      color: ${theme.colors.green200};
      font-size: 12px;
      margin: 0;
    }
  `,
  content: css`
    flex: 1;
    padding: 20px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    li {
      margin: 0 0 20px;
      padding: 0;
      font-size: 13px;
      color: ${theme.colors.gray800};
      display: flex;
      align-items: center;

      span:nth-of-type(1) {
        color: ${theme.colors.gray600};
        min-width: 80px;
        width: 80px;
        padding-right: 24px;
        text-align: right;
      }
    }
  `,
  close: css`
    padding: 0;
    background: transparent;
    color: #fff;
    border: 0;
    cursor: pointer;
    appearance: none;

    svg {
      width: 28px;
    }
  `,
  closeButton: css`
    appearance: none;
    background: ${theme.colors.green100};
    color: ${theme.colors.green700};
    border: 0;
    padding: 4px 12px;
    margin-left: 12px;
    border-radius: ${theme.borderRadius};
    cursor: pointer;
  `,
  screenshotContainer: css`
    display: block;
    padding-bottom: 24px;
  `,
  screenshot: css`
    max-width: 100%;
    height: auto;
    border-radius: 4px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  `,
  footer: css`
    color: ${theme.colors.gray600};
    padding: 16px 20px 0;
    text-align: center;
  `,
  link: css`
    color: ${theme.colors.gray500};
  `,
  poweredBy: css`
    height: auto;
    width: 144px;
  `
};
export default styles;
