import {css} from "../../../_snowpack/pkg/@emotion/react.js";
import theme from "../../lib/theme.js";
const styles = {
  container: css`
    background: #fff;
    border-radius: ${theme.borderRadius};
    bottom: 75px;
    box-shadow: ${theme.boxShadow};
    display: block;
    font-family: ${theme.fontFamily};
    overflow: hidden;
    position: fixed;
    right: 20px;
    width: 290px;

    &[aria-hidden] {
      display: none;
    }
  `,
  errorHeader: css`
    align-items: center;
    background-color: ${theme.colors.red700};
    display: flex;
    padding: 16px 20px;

    h2 {
      color: #fff;
      font-size: 18px;
      margin: 0 0 2px;
    }

    p {
      color: ${theme.colors.red200};
      font-size: 13px;
      margin: 0;
    }
  `,
  errorIcon: css`
    display: block;
    margin-right: 8px;

    svg {
      color: #fff;
      height: auto;
      width: 29px;
    }
  `,
  errorMessage: css`
    padding: 16px 24px 32px;

    a {
      color: ${theme.colors.red500};
      font-size: 14px;
      text-decoration: none;
      transition: all 0.25s linear;

      &:hover,
      &:focus,
      &:active {
        color: ${theme.colors.red700};
        text-decoration: underline;
      }
    }

    p {
      color: ${theme.colors.gray700};
      font-size: 14px;
      line-height: 1.4;
    }
  `
};
export default styles;
