import {css} from "../../../_snowpack/pkg/@emotion/react.js";
import theme from "../../lib/theme.js";
const styles = {
  container: (x, y) => css`
    background: transparent;
    border: 0;
    color: ${theme.colors.gray500};
    cursor: pointer;
    left: ${x}px;
    outline: none;
    position: absolute;
    top: ${y}px;

    &[data-selected] {
      color: ${theme.colors.green700};
    }

    svg {
      width: 24px;
    }
  `,
  pagination: css`
    bottom: 23px;
    position: absolute;
    right: 96px;
  `,
  paginationNav: css`
    align-items: center;
    background-color: #1f2937;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    display: inline-flex;
    justify-content: center;
    overflow: hidden;
    position: relative;
    z-index: 0;

    button {
      align-items: center;
      appearance: none;
      background: transparent;
      border: 0;
      color: rgba(255, 255, 255, 0.85);
      cursor: pointer;
      display: inline-flex;
      font-size: 14px;
      font-weight: medium;
      line-height: 20px;
      padding: 8px;
      position: relative;
      transition: all 0.25s linear;

      &:hover,
      &:focus,
      &:active {
        background-color: rgba(255, 255, 255, 0.1);
        color: #fff;
      }

      &:disabled {
        background: transparent;
        cursor: default;
        color: rgba(255, 255, 255, 0.25);
      }
    }

    svg {
      height: 20px;
      width: auto;
    }

    span {
      color: #fff;
      display: block;
      font-family: ${theme.fontFamily};
      font-size: 13px;
      padding: 4px 20px;
      text-align: center;
      white-space: nowrap;
      width: 80px;
    }
  `,
  srText: css`
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    word-wrap: normal !important;
  `
};
export default styles;
