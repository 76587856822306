import {css} from "../../../_snowpack/pkg/@emotion/react.js";
import theme from "../../lib/theme.js";
const styles = {
  input: css`
    border-radius: ${theme.borderRadius};
    border: 1px solid ${theme.colors.gray400};
    color: ${theme.colors.gray800};
    box-shadow: none;
    box-sizing: border-box;
    padding: 8px 12px;
    width: 100%;
  `
};
export default styles;
