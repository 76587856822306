import {css} from "../../../_snowpack/pkg/@emotion/react.js";
import theme from "../../lib/theme.js";
const styles = {
  container: (coordX, coordY) => css`
    font-family: ${theme.fontFamily};
    left: ${coordX - 12}px;
    position: fixed;
    top: ${coordY - 15}px;
    z-index: 2147483647;

    label {
      color: ${theme.colors.gray800};
      display: block;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 4px;
    }

    div {
      margin-bottom: 12px;
    }

    input,
    textarea {
      border-radius: ${theme.borderRadius};
      border: 1px solid ${theme.colors.gray400};
      box-shadow: none;
      box-sizing: border-box;
      color: ${theme.colors.gray800};
      padding: 8px 12px;
      resize: none;
      width: 100%;
    }

    textarea {
      min-height: 140px;
    }
  `,
  actions: css`
    button {
      margin-bottom: 6px;
    }
  `,
  formContainer: css`
    background-color: #fff;
    border-radius: ${theme.borderRadius};
    box-shadow: 0px 2px 5px 2px rgba(22, 30, 46, 0.06);
    box-sizing: border-box;
    margin-left: 32px;
    padding: 13px 20px;
    width: 300px;
  `,
  footer: css`
    color: ${theme.colors.gray600};
    padding: 16px 20px 0;
    text-align: center;
  `,
  link: css`
    color: ${theme.colors.gray500};
  `,
  poweredBy: css`
    height: auto;
    width: 144px;
  `
};
export default styles;
