import React from "../../_snowpack/pkg/react.js";
const Add = ({className, style}) => {
  return /* @__PURE__ */ React.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 24 24",
    className: className || void 0,
    style: style || void 0
  }, /* @__PURE__ */ React.createElement("path", {
    fill: "none",
    d: "M0 0h24v24H0z"
  }), /* @__PURE__ */ React.createElement("path", {
    fill: "currentColor",
    d: "M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
  }));
};
export default Add;
