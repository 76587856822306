import {assign, Machine} from "../../_snowpack/pkg/xstate.js";
import {closeTicket, createTicket, getTicketInput} from "./ticket.js";
import {captureScreen, getSelector} from "./utils/index.js";
const stateMachine = Machine({
  initial: "collapsed",
  context: {
    viewTicket: null,
    ticket: null,
    tickets: [],
    error: null
  },
  on: {
    RESET_STATE: {
      target: "collapsed"
    },
    INVALID_PROJECT: {
      target: "error",
      actions: assign({
        error: (ctx, e) => ({
          message: e.data
        })
      })
    }
  },
  states: {
    collapsed: {
      on: {
        TOGGLE_EXPANDED: "expanded"
      }
    },
    expanded: {
      on: {
        COLLAPSE: "collapsed",
        TOGGLE_EXPANDED: "collapsed",
        TOGGLE_REPORTING: "reportingMode",
        TOGGLE_VIEWER: "viewerMode",
        ESC_KEY: {
          target: "collapsed",
          actions: assign({
            ticket: null
          })
        }
      }
    },
    reportingMode: {
      on: {
        TOGGLE_EXPANDED: "expanded",
        TOGGLE_REPORTING: "expanded",
        ESC_KEY: {
          target: "expanded",
          actions: assign({
            ticket: null
          })
        },
        START_TICKET: {
          target: "createTicket",
          actions: assign({
            ticket: (ctx, e) => ({
              ...getTicketInput(),
              coordX: e.data.clientX,
              coordY: e.data.clientY,
              selector: getSelector(e.data)
            })
          })
        }
      }
    },
    captureScreen: {
      invoke: {
        src: () => captureScreen(),
        onDone: {
          target: "sendTicket",
          actions: assign({
            ticket: (ctx, e) => ({
              ...ctx.ticket,
              imageUrl: e.data
            })
          })
        },
        onError: {
          target: "error",
          actions: assign({
            error: () => ({
              message: "There was an error creating your ticket. Please try again later or contact BugCatcher support."
            })
          })
        }
      }
    },
    createTicket: {
      on: {
        CANCEL_TICKET: "reportingMode",
        ESC_KEY: {
          target: "expanded",
          actions: assign({
            ticket: null
          })
        },
        UPDATE_TICKET: {
          actions: assign({
            ticket: (ctx, e) => ({
              ...ctx.ticket,
              ...e.data
            })
          })
        },
        SUBMIT_TICKET: "captureScreen"
      }
    },
    sendTicket: {
      invoke: {
        src: (ctx) => createTicket(ctx.ticket),
        onDone: {
          target: "success"
        },
        onError: {
          target: "error",
          actions: assign({
            error: () => ({
              message: "There was an error creating your ticket. Please try again later or contact BugCatcher support."
            })
          })
        }
      }
    },
    closeTicket: {
      exit: assign({
        viewTicket: null
      }),
      invoke: {
        src: (ctx) => closeTicket(ctx.viewTicket),
        onDone: {
          target: "success"
        },
        onError: {
          target: "error",
          actions: assign({
            error: () => ({
              message: "There was an error closing your ticket. Please try again later or contact BugCatcher support."
            })
          })
        }
      }
    },
    success: {
      after: {
        2e3: {
          target: "expanded",
          actions: assign({
            ticket: null
          })
        }
      }
    },
    error: {
      on: {
        TOGGLE_EXPANDED: "collapsed"
      }
    },
    viewerMode: {
      on: {
        TOGGLE_EXPANDED: "expanded",
        VIEW_TICKET: {
          target: "viewTicket",
          actions: assign({
            viewTicket: (ctx, e) => ({...e.data})
          })
        }
      }
    },
    viewTicket: {
      on: {
        VIEW_TICKET: {
          target: "viewTicket",
          actions: assign({
            viewTicket: (ctx, e) => ({...e.data})
          })
        },
        TOGGLE_EXPANDED: {
          target: "expanded",
          actions: assign({
            viewTicket: null
          })
        },
        CLOSE_TICKET: {
          target: "closeTicket"
        },
        EXIT_TICKET: {
          target: "viewerMode",
          actions: assign({
            viewTicket: null
          })
        }
      }
    }
  }
});
export default stateMachine;
