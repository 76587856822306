import {jsx} from "../../../_snowpack/pkg/@emotion/react.js";
import styles from "./styles.js";
const Input = ({label, ...props}) => {
  return /* @__PURE__ */ jsx("label", {
    htmlFor: label
  }, /* @__PURE__ */ jsx("span", null, label), /* @__PURE__ */ jsx("input", {
    css: styles.input,
    name: label,
    ...props
  }));
};
export default Input;
