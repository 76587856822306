const theme = {
  borderRadius: "4px",
  boxShadow: "0 1px 3px 0 rgba(0,0,0,.1), 0 1px 2px 0 rgba(0,0,0,.06)",
  fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol'`,
  colors: {
    green100: "#F0FFF4",
    green200: "#C6F6D5",
    green300: "#9AE6B4",
    green400: "#68D391",
    green500: "#48BB78",
    green600: "#38A169",
    green700: "#2F855A",
    green800: "#276749",
    green900: "#22543D",
    gray100: "#F7FAFC",
    gray200: "#EDF2F7",
    gray300: "#E2E8F0",
    gray400: "#CBD5E0",
    gray500: "#A0AEC0",
    gray600: "#718096",
    gray700: "#4A5568",
    gray800: "#2D3748",
    gray900: "#1A202C",
    red050: "#FEF2F2",
    red100: "#FEE2E2",
    red200: "#FECACA",
    red300: "#FCA5A5",
    red400: "#F87171",
    red500: "#EF4444",
    red600: "#DC2626",
    red700: "#B91C1C",
    red800: "#991B1B",
    red900: "#7F1D1D"
  }
};
export default theme;
