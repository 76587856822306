import {jsx} from "../../../_snowpack/pkg/@emotion/react.js";
import {useContext, useState} from "../../../_snowpack/pkg/react.js";
import Pin from "../../assets/Pin.js";
import BugContext from "../../lib/context.js";
import {QueryEnum} from "../../lib/types.js";
import styles from "./styles.js";
import {useQuery} from "../../../_snowpack/pkg/react-query.js";
import {getTickets} from "../../api/queries/tickets.js";
import ChevronLeft from "../../assets/ChevronLeft.js";
import ChevronRight from "../../assets/ChevronRight.js";
const TicketOverlay = () => {
  const {state, send} = useContext(BugContext);
  const [page, setPage] = useState(1);
  const {data} = useQuery([QueryEnum.GET_ALL_TICKETS, page], () => getTickets(window?.bugcatcher?.projectId, window?.location?.href, page), {
    enabled: Boolean(window?.bugcatcher?.projectId),
    staleTime: 0
  });
  const maxPages = Math.ceil(data?.data?.total / 20) || 0;
  return /* @__PURE__ */ jsx("div", null, data?.data?.tickets?.map((ticket) => /* @__PURE__ */ jsx("button", {
    key: ticket.id,
    css: styles.container(ticket.coordX, ticket.coordY),
    "data-selected": state.context.viewTicket?.id === ticket.id || void 0,
    onClick: () => send({type: "VIEW_TICKET", data: ticket})
  }, /* @__PURE__ */ jsx(Pin, null))), maxPages > 1 && /* @__PURE__ */ jsx("div", {
    css: styles.pagination
  }, /* @__PURE__ */ jsx("nav", {
    css: styles.paginationNav,
    "aria-label": "Pagination"
  }, /* @__PURE__ */ jsx("button", {
    onClick: () => setPage(page - 1),
    disabled: page === 1
  }, /* @__PURE__ */ jsx("span", {
    css: styles.srText
  }, "Previous"), /* @__PURE__ */ jsx(ChevronLeft, {
    "aria-hidden": "true"
  })), /* @__PURE__ */ jsx("span", null, "Page ", page, " of ", maxPages), /* @__PURE__ */ jsx("button", {
    onClick: () => setPage(page + 1),
    disabled: page === maxPages
  }, /* @__PURE__ */ jsx("span", {
    css: styles.srText
  }, "Next"), /* @__PURE__ */ jsx(ChevronRight, {
    "aria-hidden": "true"
  })))));
};
export default TicketOverlay;
