import * as __SNOWPACK_ENV__ from '../_snowpack/env.js';

import {css, jsx} from "../_snowpack/pkg/@emotion/react.js";
import {createRef, useEffect, Fragment} from "../_snowpack/pkg/react.js";
import {useMachine} from "../_snowpack/pkg/@xstate/react.js";
import {useQuery} from "../_snowpack/pkg/react-query.js";
import {ReactQueryDevtools} from "../_snowpack/pkg/react-query/devtools.js";
import Bug from "./assets/Bug.js";
import Close from "./assets/Close.js";
import Menu from "./components/Menu/index.js";
import ModeTooltip from "./components/ModeTooltip/index.js";
import NewTicket from "./components/NewTicket/index.js";
import Ticket from "./components/Ticket/index.js";
import TicketOverlay from "./components/TicketOverlay/index.js";
import useClickOutside from "./hooks/useClickOutside.js";
import useKeys from "./hooks/useKeys.js";
import BugContext from "./lib/context.js";
import stateMachine from "./lib/machine.js";
import theme from "./lib/theme.js";
import {QueryEnum} from "./lib/types.js";
import {getCurrentUser} from "./api/queries/user.js";
import Error from "./components/Error/index.js";
import Loader from "./components/Loader/index.js";
const styles = {
  container: css`
    position: relative;
    z-index: 2147483647;
  `,
  button: css`
    align-items: center;
    background: ${theme.colors.green700};
    border-radius: 50%;
    border: 0;
    bottom: 20px;
    color: #fff;
    cursor: pointer;
    display: flex;
    height: 45px;
    justify-content: center;
    position: fixed;
    right: 20px;
    width: 45px;

    &[data-push-left] {
      right: 425px;
    }
  `
};
const BugCatcher = () => {
  const menuRef = createRef();
  const [state, send] = useMachine(stateMachine, {
    devTools: true
  });
  useQuery(QueryEnum.GET_CURRENT_USER, getCurrentUser, {
    retry: false
  });
  if (__SNOWPACK_ENV__.NODE_ENV === "development") {
    console.log("[stateMachine]", state);
  }
  useKeys(["Escape"], () => send("ESC_KEY"));
  useKeys(["ControlLeft", "KeyR"], () => send("TOGGLE_REPORTING"));
  useClickOutside(menuRef, state, send);
  useEffect(() => {
    const startTicket = (e) => {
      if (state.matches("reportingMode")) {
        send({type: "START_TICKET", data: e});
      }
    };
    document.addEventListener("click", startTicket);
    return () => document.removeEventListener("click", startTicket);
  }, [state.value]);
  return /* @__PURE__ */ jsx(BugContext.Provider, {
    value: {state, send}
  }, /* @__PURE__ */ jsx("div", {
    css: styles.container
  }, state.matches("createTicket") || state.matches("captureScreen") ? /* @__PURE__ */ jsx(NewTicket, null) : null, state.matches("reportingMode") && /* @__PURE__ */ jsx("span", {
    "data-html2canvas-ignore": true
  }, /* @__PURE__ */ jsx(ModeTooltip, null, "Click anywhere to begin a report.")), state.matches("viewerMode") || state.matches("loadTicket") || state.matches("viewTicket") ? /* @__PURE__ */ jsx(TicketOverlay, null) : null, state.matches("loadTicket") || state.matches("viewTicket") ? /* @__PURE__ */ jsx(Ticket, null) : null, state.matches("error") && /* @__PURE__ */ jsx(Error, null), /* @__PURE__ */ jsx("button", {
    css: styles.button,
    type: "button",
    "data-html2canvas-ignore": true,
    "data-push-left": state.matches("viewTicket") || state.matches("loadTicket") || void 0,
    onClick: () => send("TOGGLE_EXPANDED")
  }, state.matches("collapsed") ? /* @__PURE__ */ jsx(Bug, {
    style: {width: 30, height: "auto"}
  }) : /* @__PURE__ */ jsx(Fragment, null, state.matches("captureScreen") || state.matches("sendTicket") ? /* @__PURE__ */ jsx(Loader, {
    width: "22px"
  }) : /* @__PURE__ */ jsx(Close, {
    style: {width: 28, height: "auto"}
  }))), /* @__PURE__ */ jsx(Menu, {
    ref: menuRef
  }), __SNOWPACK_ENV__.NODE_ENV === "development" && /* @__PURE__ */ jsx(ReactQueryDevtools, null)));
};
export default BugCatcher;
