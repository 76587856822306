import {jsx} from "../../../_snowpack/pkg/@emotion/react.js";
import Pin from "../../assets/Pin.js";
import theme from "../../lib/theme.js";
const Marker = ({x, y}) => {
  return /* @__PURE__ */ jsx("div", {
    style: {
      position: "fixed",
      left: x - 12,
      top: y - 15,
      color: theme.colors.green700
    }
  }, /* @__PURE__ */ jsx(Pin, {
    style: {width: "24px"}
  }));
};
export default Marker;
