import {jsx} from "../../../_snowpack/pkg/@emotion/react.js";
import Toggle from "../../assets/Toggle.js";
import styles from "./styles.js";
const Select = ({
  children,
  onChange,
  label,
  value,
  ...props
}) => {
  const handleChange = (e) => {
    if (onChange) {
      return onChange(e.target.value);
    }
  };
  return /* @__PURE__ */ jsx("div", null, label ? /* @__PURE__ */ jsx("span", {
    css: styles.label
  }, label) : null, /* @__PURE__ */ jsx("div", {
    css: styles.container
  }, /* @__PURE__ */ jsx("select", {
    ...props,
    className: "text-base lg:text-sm",
    css: styles.select,
    onChange: handleChange,
    value
  }, children), /* @__PURE__ */ jsx(Toggle, null)));
};
export default Select;
