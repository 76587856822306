import {QueryClient} from "../../_snowpack/pkg/react-query.js";
export const RETRY_DEFAULT = 2;
export const STALE_TIME_DEFAULT = 1e3 * 60 * 5;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: RETRY_DEFAULT,
      staleTime: STALE_TIME_DEFAULT
    }
  }
});
export default queryClient;
