import client from "../api/client.js";
import {
  getBrowser,
  getBrowserDimensions,
  getDisplayResolution,
  getOS,
  getUserAgent
} from "./utils/index.js";
export function getTicketInput() {
  return {
    url: window.location.href,
    os: getOS(),
    browser: getBrowser(),
    userAgent: getUserAgent(),
    browserDimensions: getBrowserDimensions(),
    displayResolution: getDisplayResolution(),
    title: "",
    severity: "LOW",
    selector: "",
    coordX: 0,
    coordY: 0
  };
}
export function createTicket(data) {
  return client.post("/ticket/new", {
    ...data,
    projectUuid: window.bugcatcher.projectId
  });
}
export function closeTicket(data) {
  return client.post(`/ticket/${data?.uuid}/close`);
}
