import {jsx} from "../../../_snowpack/pkg/@emotion/react.js";
import React from "../../../_snowpack/pkg/react.js";
import BugContext from "../../lib/context.js";
import styles from "./styles.js";
const Error = () => {
  const {state} = React.useContext(BugContext);
  return /* @__PURE__ */ jsx("div", {
    css: styles.container
  }, state.matches("error") && /* @__PURE__ */ jsx(React.Fragment, null, /* @__PURE__ */ jsx("header", {
    css: styles.errorHeader
  }, /* @__PURE__ */ jsx("span", {
    css: styles.errorIcon
  }, /* @__PURE__ */ jsx("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    stroke: "currentColor"
  }, /* @__PURE__ */ jsx("path", {
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: 2,
    d: "M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
  }))), /* @__PURE__ */ jsx("div", null, /* @__PURE__ */ jsx("h2", null, "Uh oh!"))), /* @__PURE__ */ jsx("div", {
    css: styles.errorMessage
  }, /* @__PURE__ */ jsx("p", null, state.context.error.message), /* @__PURE__ */ jsx("a", {
    href: "https://bugcatcher.io/teams"
  }, "Go to BugCatcher →"))));
};
export default Error;
