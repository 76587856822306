import {useCallback, useEffect, useRef} from "../../_snowpack/pkg/react.js";
import {doesIdentifierMatchKeyboardEvent} from "../lib/utils/keyboard.js";
const defaultOptions = {
  when: true,
  continuous: false
};
function useKeys(keysList, callback, opts) {
  const options = Object.assign({}, defaultOptions, opts);
  const {target, when, continuous} = options;
  const savedCallback = useRef(callback);
  const pressedKeyMappingRef = useRef({});
  const PressedKeyMapping = pressedKeyMappingRef.current;
  useEffect(() => {
    savedCallback.current = callback;
  });
  const handleKeyDown = useCallback(function handleKeyDown2(event) {
    let pressedKeyIdentifier = null;
    let areAllKeysFromListPressed = false;
    keysList.forEach((identifier) => {
      if (doesIdentifierMatchKeyboardEvent(event, identifier)) {
        PressedKeyMapping[identifier] = true;
        pressedKeyIdentifier = identifier;
        return;
      }
    });
    if (keysList.every((identifier) => PressedKeyMapping[identifier])) {
      areAllKeysFromListPressed = true;
    }
    if (areAllKeysFromListPressed) {
      if (savedCallback.current) {
        savedCallback.current(event);
      }
      if (!continuous) {
        PressedKeyMapping[pressedKeyIdentifier] = false;
      }
    }
  }, [keysList, continuous]);
  const handleKeyUp = useCallback(function handleKeyUp2(event) {
    keysList.forEach((identifier) => {
      if (doesIdentifierMatchKeyboardEvent(event, identifier)) {
        PressedKeyMapping[identifier] = void 0;
      }
    });
  }, []);
  useEffect(() => {
    if (when && typeof window !== "undefined") {
      const targetNode = target && target.current ? target.current : document;
      if (targetNode) {
        targetNode.addEventListener("keydown", handleKeyDown);
        targetNode.addEventListener("keyup", handleKeyUp);
      }
      return () => {
        if (targetNode)
          targetNode.removeEventListener("keydown", handleKeyDown);
        if (targetNode)
          targetNode.removeEventListener("keyup", handleKeyUp);
      };
    }
  }, [when, target, keysList, handleKeyDown, handleKeyUp]);
}
export default useKeys;
