import queryClient from "./lib/react-query.js";
import React from "../_snowpack/pkg/react.js";
import ReactDOM from "../_snowpack/pkg/react-dom.js";
import {QueryClientProvider} from "../_snowpack/pkg/react-query.js";
import BugCatcher from "./App.js";
(function(d) {
  const bugcatcher = d.createElement("div");
  const body = d.getElementsByTagName("body")[0];
  bugcatcher.id = "bugcatcher-root";
  body.appendChild(bugcatcher);
})(document);
ReactDOM.render(/* @__PURE__ */ React.createElement(QueryClientProvider, {
  client: queryClient
}, /* @__PURE__ */ React.createElement(BugCatcher, null)), document.getElementById("bugcatcher-root"));
