import {css} from "../../../_snowpack/pkg/@emotion/react.js";
import theme from "../../lib/theme.js";
const styles = {
  button: css`
    border-radius: ${theme.borderRadius};
    border: 0;
    cursor: pointer;
    font-weight: bold;
    padding: 14px 24px;
    text-align: center;
    transition: background-color 0.25s ease-in-out;
    width: 100%;

    &[data-variant='primary'] {
      background-color: ${theme.colors.green700};
      color: #fff;

      &:hover,
      &:focus,
      &:active {
        background-color: ${theme.colors.green600};
      }
    }

    &[data-variant='secondary'] {
      background-color: ${theme.colors.gray300};
      color: ${theme.colors.gray800};

      &:hover,
      &:focus,
      &:active {
        background-color: ${theme.colors.gray200};
      }
    }
  `
};
export default styles;
