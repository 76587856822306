import {jsx} from "../../../_snowpack/pkg/@emotion/react.js";
import React from "../../../_snowpack/pkg/react.js";
import PoweredBy from "../../assets/PoweredBy.js";
import Button from "../Button/index.js";
import Input from "../Input/index.js";
import Marker from "../Marker/index.js";
import Select from "../Select/index.js";
import BugContext from "../../lib/context.js";
import styles from "./styles.js";
import {positionWidget} from "../../lib/utils/index.js";
const NewTicket = () => {
  const {state, send} = React.useContext(BugContext);
  const {ticket} = state.context;
  const coords = positionWidget(ticket.coordX, ticket.coordY);
  return /* @__PURE__ */ jsx("div", {
    css: styles.container(coords.x, coords.y)
  }, /* @__PURE__ */ jsx("div", {
    id: "bc-new-ticket",
    css: styles.formContainer,
    "data-html2canvas-ignore": true
  }, /* @__PURE__ */ jsx("div", null, /* @__PURE__ */ jsx(Input, {
    label: "Title",
    value: ticket.title,
    onChange: (e) => send({type: "UPDATE_TICKET", data: {title: e.target.value}})
  })), /* @__PURE__ */ jsx("div", null, /* @__PURE__ */ jsx("label", {
    htmlFor: "severity"
  }, "Severity"), /* @__PURE__ */ jsx(Select, {
    value: ticket.severity,
    onChange: (e) => send({type: "UPDATE_TICKET", data: {severity: e}})
  }, /* @__PURE__ */ jsx("option", {
    value: "LOW"
  }, "Low"), /* @__PURE__ */ jsx("option", {
    value: "MEDIUM"
  }, "Medium"), /* @__PURE__ */ jsx("option", {
    value: "HIGH"
  }, "High"), /* @__PURE__ */ jsx("option", {
    value: "CRITICAL"
  }, "Critical"))), /* @__PURE__ */ jsx("div", null, /* @__PURE__ */ jsx("label", {
    htmlFor: "description"
  }, "Description"), /* @__PURE__ */ jsx("textarea", {
    name: "description",
    value: ticket.description,
    onChange: (e) => send({
      type: "UPDATE_TICKET",
      data: {description: e.target.value}
    })
  })), /* @__PURE__ */ jsx("div", {
    css: styles.actions
  }, /* @__PURE__ */ jsx(Button, {
    variant: "primary",
    onClick: (e) => {
      e.preventDefault();
      send({type: "SUBMIT_TICKET"});
    }
  }, "Submit Ticket"), /* @__PURE__ */ jsx(Button, {
    variant: "secondary",
    onClick: (e) => {
      e.preventDefault();
      send({type: "CANCEL_TICKET"});
    }
  }, "Cancel")), /* @__PURE__ */ jsx("footer", {
    css: styles.footer
  }, /* @__PURE__ */ jsx("a", {
    href: "https://bugcatcher.io",
    target: "_blank",
    css: styles.link
  }, /* @__PURE__ */ jsx(PoweredBy, {
    css: styles.poweredBy
  })))), /* @__PURE__ */ jsx(Marker, {
    x: ticket.coordX,
    y: ticket.coordY
  }));
};
export default NewTicket;
