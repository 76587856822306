import {jsx} from "../../../_snowpack/pkg/@emotion/react.js";
import styles from "./styles.js";
const Loader = ({width}) => {
  return /* @__PURE__ */ jsx("div", {
    css: styles.loader,
    style: {width: width || "48px"}
  }, /* @__PURE__ */ jsx("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 50 50"
  }, /* @__PURE__ */ jsx("path", {
    fill: "currentColor",
    d: "M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z"
  }, /* @__PURE__ */ jsx("animateTransform", {
    attributeType: "xml",
    attributeName: "transform",
    type: "rotate",
    from: "0 25 25",
    to: "360 25 25",
    dur: "0.6s",
    repeatCount: "indefinite"
  }))));
};
export default Loader;
