import {css} from "../../../_snowpack/pkg/@emotion/react.js";
import theme from "../../lib/theme.js";
const styles = {
  container: css`
    align-items: center;
    color: ${theme.colors.gray900};
    display: flex;
    position: relative;

    svg {
      pointer-events: none;
      position: absolute;
      right: 16px;
      width: 14px;
    }

    &:focus-within svg {
      color: ${theme.colors.green700};
    }
  `,
  select: css`
    -webkit-appearance: none;
    background-color: #fff;
    border-radius: ${theme.borderRadius};
    border: 1px solid ${theme.colors.gray400};
    color: ${theme.colors.gray800};
    cursor: pointer;
    display: block;
    line-height: 1.3;
    margin: 0;
    max-width: 100%;
    outline: none;
    padding: 8px 12px;
    width: 100%;

    &::-ms-expand {
      display: none;
    }

    &:focus {
      color: ${theme.colors.green700};
    }

    option {
      font-weight: normal;
    }
  `,
  label: css`
    display: block;
    margin-bottom: 5px;
  `
};
export default styles;
