import {jsx} from "../../../_snowpack/pkg/@emotion/react.js";
import styles from "./styles.js";
const Button = ({variant, children, ...props}) => {
  return /* @__PURE__ */ jsx("button", {
    css: styles.button,
    "data-variant": variant || "primary",
    type: props.type || "button",
    ...props
  }, children);
};
export default Button;
