import React from "../../_snowpack/pkg/react.js";
const Bug = ({className, style}) => {
  return /* @__PURE__ */ React.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 154 131",
    className: className || void 0,
    style: style || void 0
  }, /* @__PURE__ */ React.createElement("g", {
    fill: "currentColor",
    fillRule: "nonzero"
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M100.8 129.2H52.5L25.4 82.3l27.1-46.9h48.3l27.1 46.9-27.1 46.9zm-41.7-11.5h35l20.4-35.4-20.4-35.4h-35L38.7 82.3l20.4 35.4z"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M76.7 83.3l-29.6-17 5.8-10L76.7 70l23.8-13.7 5.7 10z"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M70.9 76.7h11.5v46.8H70.9zM70.9 22.3h11.5v18.8H70.9z"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M76.7 29l23.8-13.8V1.9L76.7 15.7 52.9 1.9v13.3zM131.9 46.9h-13.3L139 82.3l-20.4 35.4h13.3l20.4-35.4zM14.3 82.3l20.4-35.4H21.4L1 82.3l20.4 35.4h13.3z"
  })));
};
export default Bug;
