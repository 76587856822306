import {css} from "../../../_snowpack/pkg/@emotion/react.js";
import theme from "../../lib/theme.js";
const styles = {
  container: css`
    background: #fff;
    border-radius: ${theme.borderRadius};
    bottom: 75px;
    box-shadow: ${theme.boxShadow};
    display: block;
    font-family: ${theme.fontFamily};
    overflow: hidden;
    position: fixed;
    right: 20px;
    width: 290px;

    &[aria-hidden] {
      display: none;
    }
  `,
  header: css`
    align-items: center;
    background-color: ${theme.colors.green700};
    display: flex;
    padding: 16px 20px;

    h2 {
      color: #fff;
      font-size: 18px;
      margin: 0 0 2px;
    }

    p {
      color: ${theme.colors.green200};
      font-size: 13px;
      margin: 0;
    }
  `,
  loadingContainer: css`
    color: ${theme.colors.gray500};
    font-size: 14px;
    padding: 60px 24px;
    text-align: center;
  `,
  list: css`
    list-style: none;
    margin: 0;
    padding: 16px 8px 14px;

    li {
      margin-bottom: 4px;

      &:nth-last-of-type(1) {
        margin-bottom: 0;
      }
    }

    h3 {
      color: ${theme.colors.gray800};
      font-size: 14px;
      margin: 0 0 3px;
    }

    p {
      color: ${theme.colors.gray600};
      font-size: 12px;
      margin: 0;
    }
  `,
  button: css`
    align-items: center;
    appearance: none;
    background-color: transparent;
    border-radius: ${theme.borderRadius};
    border: 0;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    padding: 8px 12px;
    text-align: left;
    text-decoration: none;
    transition: background-color 0.25s ease-in-out;
    width: 100%;

    &:hover,
    &:focus,
    &:active {
      background-color: ${theme.colors.gray200};
    }
  `,
  projectImage: css`
    align-items: center;
    background-color: ${theme.colors.green800};
    border-radius: 50%;
    color: #fff;
    display: flex;
    font-size: 20px;
    font-weight: bold;
    height: 48px;
    justify-content: center;
    margin-right: 14px;
    padding: 16px;
    width: 48px;
  `,
  icon: css`
    background-color: ${theme.colors.gray300};
    border-radius: 50%;
    color: ${theme.colors.gray800};
    line-height: 0;
    margin-right: 14px;
    padding: 12px;

    &[data-variant='primary'] {
      background-color: ${theme.colors.green200};
      color: ${theme.colors.green800};
    }

    svg {
      height: auto;
      width: 30px;
    }
  `,
  footer: css`
    color: ${theme.colors.gray600};
    padding: 12px 20px;
    text-align: center;

    a {
      display: inline-block;
    }
  `,
  link: css`
    color: ${theme.colors.gray500};
  `,
  poweredBy: css`
    height: auto;
    width: 160px;
  `,
  loginContainer: css`
    align-items: center;
    background: #fff;
    border-radius: ${theme.borderRadius};
    bottom: 75px;
    box-shadow: ${theme.boxShadow};
    display: flex;
    flex-direction: column;
    font-family: ${theme.fontFamily};
    justify-content: center;
    overflow: hidden;
    padding: 32px 16px;
    position: fixed;
    right: 20px;
    text-align: center;
    width: 290px;

    &[aria-hidden] {
      display: none;
    }

    .bug {
      color: ${theme.colors.gray600};
      display: block;
      height: 64px;
      margin-bottom: 16px;
      width: auto;
    }

    p {
      color: ${theme.colors.gray600};
      margin: 0 0 32px;
    }

    a {
      color: ${theme.colors.green600};
      text-decoration: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border-radius: ${theme.borderRadius};
      padding: 4px 18px;

      svg {
        width: 16px;
        margin-left: 4px;
        height: auto;
      }
    }
  `
};
export default styles;
