import {jsx} from "../../../_snowpack/pkg/@emotion/react.js";
import React from "../../../_snowpack/pkg/react.js";
import Add from "../../assets/Add.js";
import ExternalLink from "../../assets/ExternalLink.js";
import Pin from "../../assets/Pin.js";
import PoweredBy from "../../assets/PoweredBy.js";
import BugContext from "../../lib/context.js";
import styles from "./styles.js";
import {useQuery} from "../../../_snowpack/pkg/react-query.js";
import {QueryEnum} from "../../lib/types.js";
import {getCurrentUser} from "../../api/queries/user.js";
import Bug from "../../assets/Bug.js";
import {getProject} from "../../api/queries/project.js";
import {projectInitials} from "../../lib/utils/index.js";
import Loader from "../Loader/index.js";
const Menu = React.forwardRef((props, ref) => {
  const {state, send} = React.useContext(BugContext);
  const {data, isLoading} = useQuery(QueryEnum.GET_CURRENT_USER, () => getCurrentUser(), {
    retry: false
  });
  const {data: project, isLoading: projectLoading} = useQuery(QueryEnum.GET_PROJECT, () => getProject(window?.bugcatcher?.projectId), {
    enabled: Boolean(window?.bugcatcher?.projectId) && state.matches("expanded"),
    onError: (err) => {
      send({
        type: "INVALID_PROJECT",
        data: err.response?.data?.message || err.message
      });
    }
  });
  const loading = isLoading || projectLoading;
  if (!data?.data?.email) {
    return /* @__PURE__ */ jsx("div", {
      ref,
      css: styles.loginContainer,
      "aria-hidden": !state.matches("expanded") || void 0
    }, /* @__PURE__ */ jsx(Bug, {
      className: "bug"
    }), /* @__PURE__ */ jsx("p", null, "Log in to your BugCatcher account"), /* @__PURE__ */ jsx("a", {
      href: `https://bugcatcher.io/login?redirect=${window?.location.href}`
    }, "Login ", /* @__PURE__ */ jsx(ExternalLink, null)));
  }
  return /* @__PURE__ */ jsx("div", {
    ref,
    css: styles.container,
    "aria-hidden": !state.matches("expanded") || void 0,
    "data-html2canvas-ignore": true
  }, state.matches("expanded") && loading ? /* @__PURE__ */ jsx("div", {
    css: styles.loadingContainer
  }, /* @__PURE__ */ jsx(Loader, null)) : null, state.matches("expanded") && !loading ? /* @__PURE__ */ jsx(React.Fragment, null, /* @__PURE__ */ jsx("header", {
    css: styles.header
  }, /* @__PURE__ */ jsx("span", {
    css: styles.projectImage
  }, projectInitials(project?.data?.title || "")), /* @__PURE__ */ jsx("div", null, /* @__PURE__ */ jsx("h2", null, project?.data?.title))), /* @__PURE__ */ jsx("ul", {
    css: styles.list
  }, /* @__PURE__ */ jsx("li", null, /* @__PURE__ */ jsx("button", {
    css: styles.button,
    onClick: () => send({type: "TOGGLE_REPORTING"})
  }, /* @__PURE__ */ jsx("div", {
    css: styles.icon,
    "data-variant": "primary"
  }, /* @__PURE__ */ jsx(Add, null)), /* @__PURE__ */ jsx("div", null, /* @__PURE__ */ jsx("h3", null, "Report an issue"), /* @__PURE__ */ jsx("p", null, "Click to report a bug on your site.")))), /* @__PURE__ */ jsx("li", null, /* @__PURE__ */ jsx("button", {
    css: styles.button,
    onClick: () => send({type: "TOGGLE_VIEWER"})
  }, /* @__PURE__ */ jsx("div", {
    css: styles.icon
  }, /* @__PURE__ */ jsx(Pin, null)), /* @__PURE__ */ jsx("div", null, /* @__PURE__ */ jsx("h3", null, "View reported issues"), /* @__PURE__ */ jsx("p", null, "See the bugs reported on this page.")))), /* @__PURE__ */ jsx("li", null, /* @__PURE__ */ jsx("a", {
    css: styles.button,
    href: project?.data?.url,
    target: "_blank",
    rel: "noopener noreferrer"
  }, /* @__PURE__ */ jsx("div", {
    css: styles.icon
  }, /* @__PURE__ */ jsx(ExternalLink, null)), /* @__PURE__ */ jsx("div", null, /* @__PURE__ */ jsx("h3", null, "View project"), /* @__PURE__ */ jsx("p", null, "Go to the project home page."))))), /* @__PURE__ */ jsx("footer", {
    css: styles.footer
  }, /* @__PURE__ */ jsx("a", {
    href: "https://bugcatcher.io",
    target: "_blank",
    css: styles.link
  }, /* @__PURE__ */ jsx(PoweredBy, {
    css: styles.poweredBy
  })))) : null);
});
export default Menu;
