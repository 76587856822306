import React from "../../_snowpack/pkg/react.js";
const useClickOutside = (ref, state, send) => {
  const clickOutside = (e) => {
    if (state.matches("expanded") && !ref?.current?.contains(e.target)) {
      return send({type: "COLLAPSE"});
    }
  };
  React.useEffect(() => {
    document.addEventListener("click", clickOutside);
    return () => {
      document.removeEventListener("click", clickOutside);
    };
  });
};
export default useClickOutside;
